import React, { Component } from 'react';
import $ from 'jquery';

class Header extends Component {
    constructor() {
        super()
        this.state = {
            mobileMenu: false
        }
    }
    clickedLink = (e, type) => {
        if (this.props.history.location.pathname === '/') {
            if (type === 'logo') {
                $("html, body").animate({ scrollTop: 0 }, 500);
            } else {
                $("html, body").animate({ scrollTop: $(`#scroll-${type}`).offset().top - 300 }, 500);
            }
        } else {
            this.props.changePage(e, '/')
            setTimeout(() => {
                if (type === 'logo') {

                } else {
                    $("html, body").animate({ scrollTop: $(`#scroll-${type}`).offset().top - 300 }, 500);
                }
            }, 200);
        }
    }
    render() {
        return (
            <div className="header">
                <div className="container">
                    <div className="left">
                        <h1 onClick={(e) => this.clickedLink(e, 'logo')} id="logo">Thomas Charlesworth</h1>
                    </div>
                    <div className="menu">
                        <ion-icon onClick={() => this.setState({ mobileMenu: true })} id="menu-button" name="menu-sharp"></ion-icon>
                    </div>
                    <div className="links">
                        <a onClick={(e) => this.clickedLink(e, 'experience')} className="experience-btn" href="#">Experience</a>
                        <a onClick={(e) => this.clickedLink(e, 'projects')} className="projects-btn" href="#">Projects</a>
                        <a onClick={(e) => this.clickedLink(e, 'technology')} className="technology-btn" href="#">Technology</a>
                        <a onClick={(e) => this.clickedLink(e, 'languages')} className="languages-btn" href="#">Languages</a>
                        <a onClick={(e) => this.clickedLink(e, 'contact')} className="contact-btn" href="#">Contact</a>
                    </div>
                </div>
                {this.state.mobileMenu &&
                    <div onClick={() => {
                        this.setState({ mobileMenu: false })
                    }} class="mobile-menu">
                        <a onClick={(e) => this.clickedLink(e, 'experience')} className="experience-btn" href="#">Experience</a>
                        <a onClick={(e) => this.clickedLink(e, 'projects')} className="projects-btn" href="#">Projects</a>
                        <a onClick={(e) => this.clickedLink(e, 'technology')} className="technology-btn" href="#">Technology</a>
                        <a onClick={(e) => this.clickedLink(e, 'languages')} className="languages-btn" href="#">Languages</a>
                        <a onClick={(e) => this.clickedLink(e, 'contact')} className="contact-btn" href="#">Contact</a>
                    </div>
                }
            </div>
        );
    }
}

export default Header;
