import React from 'react';

export const projects = [
    {
        title: 'Joox Music Streaming Service',
        company: 'Tencent Thailand',
        technologies: `NextJS, Express, Tencent China's API`,
        image: './images/portfolio/joox.png',
        link: '/joox-music-streaming-service',
        repo: 'Private',
        linkSite: 'https://joox.com/th'
    },
    {
        title: 'UShop Ecommerce Site',
        company: 'Unicity Thailand',
        technologies: `React JS, MobX, Axios, Bootstrap 4 <br/> </br>
        AWS: Lambda, API Gateway, S3, CloudFront, EC2 <br/></br>
        PHP API Service
        `,
        repo: 'Private Repository',
        image: './images/portfolio/ushop.png',
        link: '/ushop-ecommerce',
        linkSite: 'https://ushop.unicity.com'
    },
    {
        title: 'AWS Micro Services & Line Bot',
        company: 'Unicity Thailand',
        technologies: `Line API <br/> </br>
        AWS: Lambda, API Gateway, S3, CloudFront, EC2 <br/></br>
        `,
        image: './images/portfolio/linebot.png',
        link: '/aws-microservices-linebot',
        repo: 'Private',
        linkSite: ''
    },
    {
        title: 'Website builder & Shell',
        company: 'LightRocket',
        technologies: 'Kohana, MariaDB, JQuery, React JS, MobX, AWS S3',
        image: './images/portfolio/builder.png',
        link: '/website-builder-shell',
        repo: 'Private',
        linkSite: 'https://lightrocket.com'
    },
    {
        title: 'Photo/Video Backup App',
        company: 'LightRocket',
        technologies: 'React Native, MobX, LightRocket APIs',
        image: './images/portfolio/app.png',
        link: '/photo-video-lightrocket-app',
        repo: 'Private',
        linkSite: 'https://www.lightrocket.com/features/app'
    },
    {
        title: 'VGO Crash Gambling',
        company: 'Million.gg',
        technologies: 'React JS, Node JS & Express, Docker, Socket IO, RethinkDB, Wax APIs',
        image: './images/portfolio/million.png',
        link: '/vgo-crash-million-gg',
        repo: 'Private',
        linkSite: ''
    },
    {
        title: 'Admin Web Portal',
        company: 'Magic Box Asia',
        technologies: 'Laravel, MySQL, JQuery, Bootstrap',
        image: './images/portfolio/mbx.png',
        link: '/magicbox-web-portal',
        repo: 'Private',
        linkSite: 'https://www.magicboxasia.com/'
    }
]

export const personal = [
    {
        title: 'SnippetRepo (Electron)',
        company: '',
        image: './images/portfolio/snippetrepo.png',
        technologies: 'Electron, React JS, React Router',
        link: '/snippet-repo',
        repo: 'https://github.com/rexjrs/electronlr',
        linkSite: ''
    },
    {
        title: 'Image Identification MLKit',
        company: '',
        technologies: 'React Native, Firebase MLKit, Native Bridge',
        image: './images/portfolio/ml.png',
        link: '/image-identification-mlkit',
        repo: 'Private',
        linkSite: ''
    },
    {
        title: 'react-native-touchable-graph',
        company: '',
        technologies: 'React Native',
        image: './images/portfolio/graph.png',
        link: '/react-native-touchable-graph',
        repo: 'https://github.com/rexjrs/react-native-touchable-graph',
        linkSite: ''
    },
    {
        title: 'Fitness Tracking App',
        company: '',
        technologies: 'React Native, MobX, React Navigation',
        image: './images/portfolio/fitness.png',
        link: '/fitness-tracking-app',
        repo: 'https://github.com/rexjrs/donerkebab',
        linkSite: ''
    }
]

function Projects(props) {
    const mapItem = props.type === 0 ? projects : personal;
    return (
        <div id="scroll-projects" className="projects">
            {mapItem.map((val) => {
                return (
                    <div key={val.title} className="port-item">
                        <img onClick={(e) => props.changePage(e, val.link)} src={val.image} alt="" />
                        <h2 onClick={(e) => props.changePage(e, val.link)}>{val.title}</h2>
                        <h3>{val.company}</h3>
                        <h4 onClick={(e) => props.changePage(e, val.link)}>Learn More</h4>
                    </div>
                )
            })}
        </div>
    );
}

export default Projects;
