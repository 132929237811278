import React from 'react';

function Project(props) {
    const { data } = props;
    return (
        <div className="project">
            <div className="seperator">
                <h1>{data.title}</h1>
            </div>
            <div className="container">
                <div className="left">
                    {data.company !== '' &&
                        <React.Fragment>
                            <h2>Client:</h2>
                            <h3>{data.company}</h3>
                        </React.Fragment>
                    }
                    <h2>Technologies:</h2>
                    <p dangerouslySetInnerHTML={{ __html: data.technologies }}></p>
                    <h2>Github repository:</h2>
                    <h3>{data.repo}</h3>
                    {data.linkSite !== '' &&
                        <React.Fragment>
                            <h2>Link to project:</h2>
                            <a target="_blank" href={data.linkSite}><h3>{data.linkSite}</h3></a>
                        </React.Fragment>
                    }
                </div>
                <div className="right">
                    <img src={data.image} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Project;
