import React from 'react';

function Banner() {
    return (
        <div id="banner" className="background-container">
            <div className="about me">
                <div className="left">
                    <h1>Hi, I'm Thomas</h1>
                    <h3>
                        Fullstack and 'mobile' (React Native) developer based in Margate, United Kingdom. <br />
                        I develop web and mobile solutions for businesses using modern technology. 
            </h3>
                </div>
            </div>
        </div>
    );
}

export default Banner;
