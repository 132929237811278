import React, { Component } from 'react';
import Banner from '../components/Banner';
import Experience from '../components/Experience';
import Projects from '../components/Projects';
import Tech from '../components/Tech';
import Languages from '../components/Languages';

class Home extends Component {
    constructor() {
        super()
        this.state = {
            projectType: 0
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <Banner />
                    <div className="seperator">
                        <h1>Professional Experience</h1>
                    </div>
                    <Experience />
                    <div className="background">
                        <div className="seperator">
                            <h1>Projects</h1>
                        </div>
                        <div className="tab-container">
                            <button
                                onClick={() => this.setState({ projectType: 0 })}
                                className={`tab ${this.state.projectType === 0 ? 'active' : ''}`}
                            >
                                <h1>Professional</h1>
                            </button>
                            <button
                                onClick={() => this.setState({ projectType: 1 })}
                                className={`tab ${this.state.projectType === 1 ? 'active' : ''}`}
                            >
                                <h1>Personal</h1>
                            </button>
                        </div>
                        <Projects changePage={this.props.changePage} type={this.state.projectType} />
                    </div>
                    <div id="scroll-tech" className="seperator">
                        <h1>Technology</h1>
                    </div>
                    <Tech />
                    <div className="background">
                        <div id="scroll-lang" className="seperator">
                            <h1>Languages</h1>
                        </div>
                        <Languages />
                    </div>
                    <div id="scroll-contact" className="seperator">
                        <h1>Contact & Links</h1>
                    </div>
                    <div id="scroll-contact" className="contact">
                        <h3>E-Mail: <a href="mailto:thomas.charlesworths@gmail.com">thomas.charlesworths@gmail.com</a></h3>
                        <br />
                        <h3>Skype: <a href="skype:profile_name?live:.cid.b2694dd8a54e8e5f">live:.cid.b2694dd8a54e8e5f</a></h3>
                        <br />
                        <h3>Discord: Thomas#1671</h3>
                        <br />
                        <h3>Linkedin: <a target="_blank"
                            href="https://www.linkedin.com/in/thomas-charlesworth-140b12157">https://www.linkedin.com/in/thomas-charlesworth-140b12157</a>
                        </h3>
                        <br />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;
