import React from 'react';

function Experience() {
    return (
        <div id="scroll-experience" className="experience">
            <h2>
                Internship @ Magic Box Asia Thailand | Mar 2014 - May 2014
            </h2>
            <h2>Full Stack and React Native Developer @ Unicity Thailand | Nov 2016 - Nov 2017</h2>
            <h2>Contractor @ Unicity Thailand | Nov 2017 - Mar 2020</h2>
            <h2>Assistant Engineer and Teacher @ Tencent Thailand | Nov 2017- Jan 2018</h2>
            <h2>Software Engineer @ LightRocket Singapore | Jan 2018 - Apr 2020</h2>
        </div>
    );
}

export default Experience;
