import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import Project from './pages/Project';
import { projects, personal } from './components/Projects';
import $ from 'jquery';
const history = createBrowserHistory();

class App extends Component {
    constructor() {
        super()
        this.state = {
            projectType: 0,
            showBackTop: false
        }
        this.showBackTop = false;
    }
    componentDidMount() {
        const that = this;
        $(window).scroll(function () {
            const topHeight = $('#banner').height() - 300;
            const scroll = $(window).scrollTop();
            if (scroll >= topHeight) {
                if (!that.showBackTop && history.location.pathname === '/') {
                    that.showBackTop = true;
                    that.setState({
                        showBackTop: true
                    })
                }
            }
            if (scroll < topHeight) {
                if (that.showBackTop) {
                    that.showBackTop = false;
                    that.setState({
                        showBackTop: false
                    })
                }
            }
        });
    }
    changePage = (e, url) => {
        e.preventDefault();
        if (url !== '/') {
            this.setState({ showBackTop: false })
        }
        history.push(url)
    }
    render() {
        return (
            <React.Fragment>
                <Header history={history} changePage={this.changePage} />
                {this.state.showBackTop && <div onClick={() => $("html, body").animate({ scrollTop: 0 }, 500)} className="backtop">↑</div>}
                <Router history={history}>
                    <Route exact path='/' render={(props) => {
                        return <Home changePage={this.changePage} {...props} />
                    }} />
                    {projects.map((val) => {
                        return (
                            <Route key={val.title} exact path={val.link} render={(props) => {
                                return <Project data={val} changePage={this.changePage} {...props} />
                            }} />
                        )
                    })}
                    {personal.map((val) => {
                        return (
                            <Route key={val.title} exact path={val.link} render={(props) => {
                                return <Project data={val} changePage={this.changePage} {...props} />
                            }} />
                        )
                    })}
                </Router>
            </React.Fragment >
        );
    }
}

export default App;
