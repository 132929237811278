import React from 'react';

function Tech() {
    return (
        <React.Fragment>
            <div id="scroll-technology" className="tech">
                <div className="left">
                    <h2>React JS Stack</h2>
                    <div className="stack">
                        <img src="./images/tech/react.png" alt="" />
                        <img src="./images/tech/nextjs.png" alt="" />
                        <img src="./images/tech/mobx.png" alt="" />
                        <img style={{ opacity: 0.2 }} src="./images/tech/redux.png" alt="" />
                        <div className="router-wrapper">
                            <img src="./images/tech/reactrouter.png" alt="" />
                        </div>
                        <img src="./images/tech/axios.png" alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>React JS, NextJS</h3>
                    <h3>MobX, Redux</h3>
                    <h3>React Router</h3>
                    <h3>Axios</h3>
                </div>
            </div>
            <div className="tech">
                <div className="left">
                    <h2>Mobile Apps Stack (React Native)</h2>
                    <div className="stack">
                        <img src="./images/tech/react.png" alt="" />
                        <img src="./images/tech/mobx.png" alt="" />
                        <img style={{ opacity: 0.2 }} src="./images/tech/redux.png" alt="" />
                        <img src="./images/tech/reactnavigation.svg" alt="" />
                        <img src="./images/tech/axios.png" alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>React Native</h3>
                    <h3>MobX, Redux</h3>
                    <h3>React Navigation</h3>
                    <h3>Axios</h3>
                </div>
            </div>
            <div className="tech">
                <div className="left">
                    <h2>Other JS Frameworks / Libraries</h2>
                    <div className="stack">
                        <img src="./images/tech/vue.png" alt="" />
                        <img src="./images/tech/jquery.png" alt="" />
                        <img src="./images/tech/electron.png" alt="" />
                        <img src="./images/tech/knex.png" alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>VueJS</h3>
                    <h3>JQuery</h3>
                    <h3>Electron</h3>
                    <h3>KnexJS</h3>
                </div>
            </div>
            <div className="tech">
                <div className="left">
                    <h2>Cloud Services</h2>
                    <div className="stack">
                        <img src="./images/tech/aws.png" alt="" />
                        <img src="./images/tech/firebase.png" alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>Firebase - Cloud Functions, Firestore, Storage, MLKit</h3>
                    <h3>AWS Lambda</h3>
                    <h3>AWS API Gateway</h3>
                    <h3>AWS S3</h3>
                    <h3>AWS CloudFront</h3>
                    <h3>AWS DynamoDB</h3>
                    <h3>AWS Elastic Beanstalk</h3>
                    <h3>AWS CodePipeline</h3>
                </div>
            </div>
            <div className="tech">
                <div className="left">
                    <h2>Back-End Frameworks</h2>
                    <div className="stack">
                        <img src="./images/tech/express.png" alt="" />
                        <img src="./images/tech/laravel.png" alt="" />
                        <img src="./images/tech/kohana.svg" alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>Express JS</h3>
                    <h3>Laravel</h3>
                    <h3>Kohana</h3>
                </div>
            </div>
            <div className="tech">
                <div className="left">
                    <h2>NoSQL Databases</h2>
                    <div className="stack">
                        <img src="./images/tech/dynamodb.svg" alt="" />
                        <img src="./images/tech/firestore.png" alt="" />
                        <img src="./images/tech/rethinkdb.jpg" alt="" />
                        <img src="./images/tech/mongodb.jpg" alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>DynamoDB</h3>
                    <h3>Firestore</h3>
                    <h3>RethinkDB</h3>
                    <h3>MongoDB</h3>
                </div>
            </div>
            <div className="tech">
                <div className="left">
                    <h2>SQL Databases</h2>
                    <div className="stack">
                        <img src="./images/tech/mysql.png" alt="" />
                        <img src="./images/tech/mariadb.png" alt="" />
                        <img src="./images/tech/postgres.jpg" alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>MySQL</h3>
                    <h3>MariaDB</h3>
                    <h3>PosgreSQL</h3>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Tech;
