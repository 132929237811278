import React from 'react';

function Languages() {
    return (
        <div id="scroll-languages" className="languages">
            <div className="left">
                <h3>Javascript ES5/ES6</h3>
                <h3>Node JS</h3>
                <h3>PHP</h3>
            </div>
        </div>
    );
}

export default Languages;
